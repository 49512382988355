import Button from '@/components/ui/Button';
import Footer from '@/components/ui/Footer';
import { useUser } from '@/utils/useUser';
import { EyeIcon } from '@heroicons/react/outline';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export default function IndexPage({}) {
  const { user } = useUser();
  const router = useRouter();

  useEffect(() => {
    if (router.asPath.includes('#access_token')) {
      if (router.asPath.includes('type=recovery')) {
        router.replace(`/password-recorvery${router.asPath.replace('#', '?')}`);
      } else router.replace('/dashboard');
    }
  }, []);

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'VideoObject',
              name: 'Notion charts with Nochart',
              description:
                'Demo of how to create your own Notion charts using Nochart.',
              thumbnailUrl: ['https://nochart.co/og.png'],
              uploadDate: '2021-09-30T08:00:00+08:00',
              contentUrl: 'https://nochart.co/demo-nochart.mp4',
              duration: 'PT10S'
            })
          }}
        />
      </Head>
      <div className="max-w-6xl m-auto text-center pt-16 px-3 ">
        <div>
          <Image
            src="/notion+chart.svg"
            alt="charts syncr logo"
            height="45"
            width="200"
          />
          <h2 className="max-w-2xl mx-auto mt-3 text-3xl md:text-5xl font-extrabold leading-tight">
            Turn your Notion database into actionable insights
          </h2>

          <h1 className="max-w-2xl m-auto mt-2 text-xl">
            Interactive and customizable Notion charts.
          </h1>
          <div className="flex w-full justify-center space-x-4">
            <Link href={user ? '/dashboard' : '/signup'}>
              <Button className="mt-6">{user ? 'My charts' : 'Sign up'}</Button>
            </Link>

            <a
              target="_blank"
              href="https://nochart.notion.site/Nochart-Examples-9c78d939d745474ab340dd2be337706c"
            >
              <Button className="mt-6" variant="secondary">
                <EyeIcon className="h-5 w-5 mr-1" /> Live examples
              </Button>
            </a>
          </div>
        </div>
        <div className="max-w-2xl mx-auto mt-10">
          <video
            loop
            autoPlay
            muted
            width="672"
            height="377"
            className="shadow-2xl border border-gray-400 rounded-md"
          >
            <source src="demo-nochart.webm" type="video/webm" />
            <source src="demo-nochart.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="w-full bg-red-100 mt-32">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row text-left w-full py-16 md:py-32 md:space-x-10 space-y-10">
          <div className="md:w-1/3 px-4 md:px-10 my-auto">
            <h2 className="text-2xl md:text-4xl font-bold mb-4 leading-tight ">
              Embed your Notion chart on any page with one click
            </h2>
            <p className="text-lg">
              Our charts are built to look great in Notion and easily embedable
              on any page public or private.
            </p>
          </div>
          <div className="px-10">
            <video
              loop
              autoPlay
              muted
              width="550"
              height="310"
              className="shadow-2xl rounded-md border border-gray-400"
            >
              <source src="Embed.webm" type="video/webm" />
              <source src="Embed.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row text-left w-full py-16 md:py-32 md:space-x-10 space-y-10">
          <div className="md:w-1/3 px-4 md:px-10 my-auto md:order-2">
            <h2 className="text-2xl md:text-4xl font-bold mb-4 leading-tight ">
              Let our AI create your notion charts
            </h2>
            <p className="text-lg">
              Get inspirations from Nochart and find out what's interesting to
              visualize in your Notion database.
            </p>
          </div>
          <div className="px-4 md:order-1">
            <video
              loop
              autoPlay
              muted
              width="550"
              height="310"
              className="shadow-2xl rounded-md border border-gray-400"
            >
              <source src="auto-chart.webm" type="video/webm" />
              <source src="auto-chart.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="w-full bg-red-100">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row text-left w-full py-16 md:py-32 md:space-x-10 space-y-10">
          <div className="md:w-1/3 px-4 md:px-10 my-auto ">
            <h2 className="text-2xl md:text-4xl font-bold mb-4 leading-tight ">
              Create your notion chart using human language
            </h2>
            <p className="text-lg">
              X, Y... ¿Qué pasa? <br />
              Simply tell Nochart what you want to see. <br /> e.g. "I want to
              sum Earnings per customers".
            </p>
          </div>
          <div className="px-4">
            <video
              loop
              autoPlay
              muted
              width="550"
              height="310"
              className="shadow-2xl rounded-md border border-gray-400"
            >
              <source src="I want to.webm" type="video/webm" />
              <source src="I want to.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className="w-full py-16 md:py-32">
        <div className="max-w-6xl mx-auto">
          <h2 className="max-w-2xl text-center mx-auto mt-3 text-3xl md:text-5xl font-bold leading-tight">
            Start with a template
          </h2>
          <div className="max-w-lg px-10 justify-center mt-9 mx-auto flex flex-row flex-wrap">
            {[
              {
                title: '🕒 Freelance time tracking',
                url:
                  'https://nochart.notion.site/Freelance-Time-Tracking-18a0327efdff4e1facc5c609b655db70'
              },
              {
                title: '💸 Budget Tracker',
                url:
                  'https://nochart.notion.site/Simple-Budget-Tracker-520eadf1b5a8474f9e032baeced81641'
              },
              {
                title: '💰 Sales CRM',
                url:
                  'https://nochart.notion.site/Sales-CRM-f1afd088d7f54bd4bdb02b8d10284ffc'
              },
              {
                title: '📓 Reading List',
                url:
                  'https://nochart.notion.site/Reading-List-65f69d7950e14369ad9b964ac6825f43'
              }
            ].map((link) => (
              <div
                className="w-full underline hover:text-accents-8 mb-2 text-xl"
                key={link.url}
              >
                <a href={link.url}>{link.title}</a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
