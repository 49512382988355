import Link from 'next/link';
export default function Footer() {
  return (
    <footer className=" w-full px-6 bg-primary border-accents-5 pt-6 " style={{marginTop: '80px'}}>
      <div className="max-w-8xl py-4 mx-auto flex flex-col md:flex-row bg-primary justify-center ">
        <div className="flex text-accents-7 flex-wrap" style={{'gridGap' : '16px'}}>
          <span>&copy; 2021 Nochart, Inc. All rights reserved.</span>
          <Link href='/blog'><a className="text-accents-6 hover:text-accents-8 transition ease-in-out duration-150 mx-0.5">Blog</a></Link>
          <a
            className="text-accents-6 hover:text-accents-8 transition ease-in-out duration-150 mx-0.5"
            href="https://nochart.notion.site/Privacy-Policy-8e08962462b249c99ff0a3ec9952491c"
            target='_blank'
            rel="nofollow"
          >
            Privacy Policy
          </a>
          <a
            className="text-accents-6 hover:text-accents-8 transition ease-in-out duration-150 mx-0.5"
            href="https://nochart.notion.site/Terms-and-Conditions-4ab919a8fd1543ca83363793a89e1d6d"
            target='_blank'
            rel="nofollow"
          >
            Terms of Use
          </a>
        </div>
      </div>
    </footer>
  );
}
